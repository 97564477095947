<div class="dropdown">
	<div class="dropdown-header" fxLayout="row" fxLayoutAlign="space-between center"
	[ngClass]="(fuseConfig.layout.style == 'horizontal-layout-1' ? 'warn' : 'primary')">
	
		<div fxLayout="row" fxLayoutAlign="start center">
			<div class="dropdown-heading">{{person.firstName}} {{person.lastName}}</div>
		</div>
	</div>
	<div class="dropdown-content">
		<a (click)="close()" *ngFor="let item of items; trackBy: trackById" [routerLink]="item.route"
			class="notification py-8" fxLayout="row" fxLayoutAlign="start center" matRipple>
			 <!-- <tinc-icon [ngClass]="item.colorClass" class="notification-icon" fxFlex="none" [icon]="item.icon"></tinc-icon> -->
			 <mat-icon [ngClass]="item.colorClass" class="notification-icon pl-4" fxFlex="none">{{item.icon}}</mat-icon>
			<div fxFlex="auto">
				<div class="notification-label">{{ item.label }}</div>
				<div class="notification-description">{{ item.description }}</div>
			</div>
		</a>
	</div>
	<div class="dropdown-footer" fxLayout="row" fxLayoutAlign="space-between center">
		<a (click)="logout()" color="primary" mat-button>
			<tinc-icon icon="logout"></tinc-icon>
			LOGOUT
		</a>
	</div>
</div>
